import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import "../App.css";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";

function Navbar() {
  const { login, setLogin } = useContext(AuthContext);
  const { count, setCount } = useContext(AuthContext);
  // function countItem() {
  //   let items = JSON.parse(localStorage.getItem("Items"));
  //   setcount(items.length);
  // }

  const handleLogout = () => {
    toast.success("Logout Successfully", {
      position: "bottom-center",
      autoClose: 1000,
    });
    setLogin({ isloggedin: false });
    localStorage.setItem("Login", false);
  };

  useEffect(() => {
    try {
      let userLogin = JSON.parse(localStorage.getItem("login"));
    } catch (error) {}
  }, []);

  return (
    <nav className="navbar main-navbar  navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand text-success" href="/">
          <Link to={"/"} className="nav-link active" aria-current="page">
            Lucky Fruits
          </Link>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          id="navbarSupportedContent"
          className="collapse navbar-collapse show"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <div className="Navbar-login">
            <Link
              to="cart"
              className="fa-solid fa-cart-shopping cart-icon align-center"
            ></Link>
            <p className="cart-count">{count == null ? 0 : count}</p>
            {login.isloggedin ? (
              <Link to="login" className="btn login-btn mx-3">
                <h5 onClick={handleLogout}>Logout</h5>
              </Link>
            ) : (
              <Link to="login" className="btn login-btn mx-3">
                <h5>Login</h5>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
