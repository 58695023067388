import { useState } from "react";
import { AuthContext } from "./AuthContext";

export const AuthContextProvider = (props) => {
  const [login, setLogin] = useState({
    isloggedin:false
  });
  const [count, setCount] = useState(0);

  return (
    <AuthContext.Provider value={{ login, setLogin,count,setCount }}>
      {props.children}
    </AuthContext.Provider>
  );
};
